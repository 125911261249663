var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page",attrs:{"id":"usageSummary"}},[_c('h1',{staticClass:"my-2"},[_vm._v(_vm._s(_vm.$t('usage.summary.title')))]),_c('div',{staticClass:"mb-2 px-4 py-3 card"},[_c('div',{staticClass:"d-flex flex-wrap justify-content-between align-items-center mb-2"},[_c('div',{staticClass:"d-flex flex-column align-items-start"},[_c('label',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.$t('usage.summary.filters.date-range.label')))]),_c('div',{staticClass:"d-flex gap-2"},[_c('DatePicker',{attrs:{"config":{
              maxDate: 'today',
              dateFormat: 'Y-m-d',
              altFormat: 'F j, Y'
            },"mode":"single"},on:{"input":_vm.fetchUsageSummary},model:{value:(_vm.filters.dateRange.from),callback:function ($$v) {_vm.$set(_vm.filters.dateRange, "from", $$v)},expression:"filters.dateRange.from"}}),_c('DatePicker',{attrs:{"config":{
              maxDate: 'today',
              dateFormat: 'Y-m-d',
              altFormat: 'F j, Y'
            },"mode":"single"},on:{"input":_vm.fetchUsageSummary},model:{value:(_vm.filters.dateRange.to),callback:function ($$v) {_vm.$set(_vm.filters.dateRange, "to", $$v)},expression:"filters.dateRange.to"}})],1)]),_c('div',{staticClass:"d-flex flex-column align-items-start"},[_c('label',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.$t('usage.summary.filters.show-by.label')))]),_c('Select',{attrs:{"preselectFirst":false,"multiple":"","options":_vm.groupByOptions},on:{"input":_vm.fetchUsageSummary},model:{value:(_vm.filters.groupBy),callback:function ($$v) {_vm.$set(_vm.filters, "groupBy", $$v)},expression:"filters.groupBy"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8"},[_c('toggle-button',{attrs:{"options":_vm.groupByToggleOptions},on:{"change":_vm.fetchUsageSummary},scopedSlots:_vm._u([{key:"cardContent",fn:function({ item }){return [_c('div',{staticClass:"card",class:{ active: item.id === _vm.filters.groupByToggle.id }},[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(item.name))])])]}}]),model:{value:(_vm.filters.groupByToggle),callback:function ($$v) {_vm.$set(_vm.filters, "groupByToggle", $$v)},expression:"filters.groupByToggle"}})],1)])]),_c('half-circle-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"pre-loader",attrs:{"size":50,"color":"#008ecf"}}),(!_vm.isLoading)?_c('DataTable',{attrs:{"fields":_vm.fields,"data":_vm.data,"pagination":_vm.pagination},on:{"pageChange":_vm.pageChange}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }