<template>
  <div class="w-100 items row">
    <div
      class="col-12 col-lg-4 item"
      v-for="option in options"
      @click="selectOption(option)"
      :key="option.id"
    >
      <slot name="cardContent" :item="option">{{ option }}</slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Object
    }
  },
  methods: {
    selectOption(option) {
      this.$emit('input', option)
      this.$emit('change', option)
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  cursor: pointer;
}
</style>
