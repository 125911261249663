<template>
  <div class="page" id="usageSummary">
    <h1 class="my-2">{{ $t('usage.summary.title') }}</h1>

    <div class="mb-2 px-4 py-3 card">
      <div
        class="d-flex flex-wrap justify-content-between align-items-center mb-2"
      >
        <div class="d-flex flex-column align-items-start">
          <label class="m-0">{{
            $t('usage.summary.filters.date-range.label')
          }}</label>
          <div class="d-flex gap-2">
            <DatePicker
              v-model="filters.dateRange.from"
              @input="fetchUsageSummary"
              :config="{
                maxDate: 'today',
                dateFormat: 'Y-m-d',
                altFormat: 'F j, Y'
              }"
              mode="single"
            />
            <DatePicker
              v-model="filters.dateRange.to"
              @input="fetchUsageSummary"
              :config="{
                maxDate: 'today',
                dateFormat: 'Y-m-d',
                altFormat: 'F j, Y'
              }"
              mode="single"
            />
          </div>
        </div>
        <div class="d-flex flex-column align-items-start">
          <label class="m-0">{{
            $t('usage.summary.filters.show-by.label')
          }}</label>

          <Select
            :preselectFirst="false"
            multiple
            :options="groupByOptions"
            v-model="filters.groupBy"
            @input="fetchUsageSummary"
          >
          </Select>
        </div>
      </div>

      <div class="row">
        <div class="col-8">
          <toggle-button
            :options="groupByToggleOptions"
            v-model="filters.groupByToggle"
            @change="fetchUsageSummary"
          >
            <template #cardContent="{ item }">
              <div
                class="card"
                :class="{ active: item.id === filters.groupByToggle.id }"
              >
                <p class="m-0">{{ item.name }}</p>
              </div>
            </template>
          </toggle-button>
        </div>
      </div>
    </div>

    <half-circle-spinner
      v-show="isLoading"
      :size="50"
      color="#008ecf"
      class="pre-loader"
    />
    <DataTable
      :fields="fields"
      :data="data"
      :pagination="pagination"
      v-if="!isLoading"
      @pageChange="pageChange"
    >
    </DataTable>
  </div>
</template>

<script>
import DataTable from '../../../elements/DataTable.vue'
import UsageService from '../../../services/usage.service'
import DatePicker from '../../../elements/DatePicker.vue'
import moment from 'moment'
import Select from '../../../elements/Select.vue'
import ToggleButton from '../../../elements/ToggleButton.vue'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'

export default {
  components: {
    HalfCircleSpinner,
    DataTable,
    DatePicker,
    ToggleButton
  },
  data() {
    return {
      fields: [],
      data: [],
      pagination: {
        currentPage: 1,
        perPage: 10,
        totalItems: 0
      },
      isLoading: false,
      filters: {
        dateRange: {
          from: moment()
            .subtract(30, 'days')
            .format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD')
        },
        groupBy: [],
        groupByToggle: {
          id: '',
          name: 'Summary'
        }
      }
    }
  },
  computed: {
    groupByOptions() {
      return [
        {
          id: 'codeName',
          name: this.$t('usage.summary.filters.show-by.options.code')
        },
        {
          id: 'accountsName',
          name: this.$t('usage.summary.filters.show-by.options.account')
        },
        {
          id: 'packagesName',
          name: this.$t('usage.summary.filters.show-by.options.package')
        }
      ]
    },
    groupByToggleOptions() {
      return [
        {
          id: '',
          name: this.$t('usage.summary.filters.group-by-options.summary')
        },
        {
          id: 'dateDay',
          name: this.$t('usage.summary.filters.group-by-options.day')
        },
        {
          id: 'dateMonth',
          name: this.$t('usage.summary.filters.group-by-options.month')
        }
      ]
    }
  },
  methods: {
    pageChange(page) {
      console.log('pageChange', page)
    },
    async fetchUsageSummary() {
      this.isLoading = true
      try {
        const filters = { ...this.filters }

        const difference = moment(filters.dateRange.from).diff(
          moment(filters.dateRange.to),
          'days'
        )
        if (Math.abs(difference) > 90) {
          this.toast(
            this.$t('usage.summary.filters.date-range.errors.invalid', {
              days: 90
            }),
            {
              type: 'error'
            }
          )
          this.isLoading = false
          return
        }

        filters.groupBy = [
          ...filters.groupBy.map(groupBy => groupBy.id),
          this.filters.groupByToggle.id
        ]
          .filter(g => !!g)
          .join(',')
        const res = await UsageService.fetchUsageSummary(filters)
        this.data = res.list
        this.fields = res.columns.map(column => ({
          name: column,
          title: this.$t(`usage.summary.table.columns.${column}`)
        }))
        this.pagination.totalItems = res.list.length
      } catch (error) {
        this.toast(
          error.response ? error.response.data.message : error.message,
          {
            type: 'error'
          }
        )
      } finally {
        this.isLoading = false
      }
    }
  },
  mounted() {
    this.fetchUsageSummary()
  }
}
</script>

<style lang="scss" scoped>
.card {
  padding: 0.5rem 1rem;

  &.active {
    background: #3090df;
    color: white;
  }
}
.gap-2 {
  gap: 0.5rem;
}
</style>
